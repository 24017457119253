:root {
  background-color: var(--color-red);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .hide-in-mobile {
    display: none;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
}

section.page-main {
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease, visibility 1s ease;
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  height: calc(100% - 96px);
}

section.page-main.active {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease, visibility 1s ease;
}

.page-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.frame {
  position: relative;
  width: 25vw;
  height: 25vw;
  margin: 0 auto;
  background: black;
  clip-path: polygon(
    92.5% 50%,
    96.98% 32.9%,
    82.56% 22.68%,
    75% 6.7%,
    57.38% 8.15%,
    41.32% 0.76%,
    28.75% 13.19%,
    11.7% 17.86%,
    10.06% 35.46%,
    0% 50%,
    10.06% 64.54%,
    11.7% 82.14%,
    28.75% 86.81%,
    41.32% 99.24%,
    57.38% 91.85%,
    75% 93.3%,
    82.56% 77.32%,
    96.98% 67.1%
  );
}

.frame img {
  position: absolute;
  top: 2px; /* Border width */
  bottom: 2px;
  left: 2px;
  right: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  object-fit: cover;
  clip-path: inherit;
}

@media screen and (max-width: 1200px) {
  .hide-in-small-width {
    display: none;
  }
}

.flex-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.page-center-right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

/*========================================================================*/
/*=========================== Contacts ===================================*/
/*========================================================================*/

.contacts-content p {
  margin: 0 var(--gap-sm);
}

#send-email-form {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: var(--gap-md) 0;
  color: var(--color-black);
}

#send-email-form input,
#send-email-form textarea {
  margin: var(--gap-sm) 0;
  padding: var(--gap-md);
  border: none;
  width: 100%;
  font-size: 1.2rem;
  color: var(--color-black);
  background-color: inherit;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.1);
}

#send-email-form textarea {
  height: 100px;
}

#send-email-form input:hover,
#send-email-form textarea:hover {
  opacity: 1;
  color: #fff;
  border-left: 2px solid var(--color-red);
}

#send-email-form input:focus,
#send-email-form textarea:focus {
  opacity: 1;
  color: #fff;
  border: 0;
  border-left: 2px solid var(--color-red);
  outline: none;
}

#send-email-form button {
  display: block;
  margin: var(--gap-lg) 0;
  padding: var(--gap-sm);
  border: none;
  width: 100%;
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  background-color: var(--color-red);
  text-transform: uppercase;
  cursor: pointer;
}

.scroll-contacts {
  height: 100%;
  width: 100%;
}

.contacts-wrapper {
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contacts-wrapper::-webkit-scrollbar {
  display: none;
}

.contacts-flex-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.contacts-additional-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.scroll-contacts {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-contacts::-webkit-scrollbar {
  display: none;
}

.contacts-wrapper {
  overflow-y: scroll;
}

.email-form {
  width: 100%;
}

.contacts-flex-wrapper {
  justify-content: flex-start;
}

.lets-connect {
  padding: var(--gap-lg);
}

.scroll-about-me {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 var(--var-md);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-about-me::-webkit-scrollbar {
  display: none;
}

.about-me-flex-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
}

.frame {
  width: 250px;
  height: 250px;
  margin: 25px auto;
}

/*======================================================================*/
/*========================= moving icons ===============================*/
/*======================================================================*/

.moving_icons img {
  width: 60px;
  animation: moving-icons-animation 1s infinite;
}

@keyframes moving-icons-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.icons-margin img {
  margin: var(--gap-md) var(--gap-md) 0 0;
}

/* ===================================================================== */
/* ========================= small navigation ========================== */
/* ===================================================================== */

.small-navigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: var(--gap-md) 0;
}

.small-navigation a {
  color: var(--color-black);
  text-decoration: none;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.1em;
}

.link {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.link-margin {
  margin-right: var(--gap-lg);
}

.link::before,
.link::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}

.link::after {
  content: attr(replace);
  height: 100%;
  top: 0;
  transform-origin: 50% 0%;
  transform: translate3d(0%, 200%, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.link:hover::before {
  transform-origin: 50% 0%;
  transform: scaleX(1);
}

.link:hover::after {
  transform: translate3d(0, 0, 0);
}

.link span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.link:hover span {
  transform: translate3d(0, -200%, 0);
}

.skills-column {
  background-color: var(--color-yellow);
  z-index: 1;
}

.language-column {
  background-color: var(--color-lilac);
  z-index: 2;
}

.education-column {
  background-color: var(--color-green);
  z-index: 3;
}

#skills-page-grid {
  width: 100%;
  height: 100%;
  transition: 500ms;
  display: grid;
  grid-template-rows: 2.5fr 0.25fr 0.25fr;
  gap: 2px;
  background: var(--color-black);
}

:where(.skills-column, .language-column, .education-column) {
  background: inherit;
  transition: 300ms;
}

#skills-page-grid:has(.skills-column:hover) {
  grid-template-rows: 2.5fr 0.25fr 0.25fr;
}

#skills-page-grid:has(.language-column:hover) {
  grid-template-rows: 0.25fr 2.5fr 0.25fr;
}

#skills-page-grid:has(.education-column:hover) {
  grid-template-rows: 0.25fr 0.25fr 2.5fr;
}

.skills-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.skills-content {
  position: absolute;
  top: var(--gap-xl);
  left: 0;
  padding: 0 var(--gap-sm);
  padding-top: var(--gap-md);
}

.scroll-skills {
  position: relative;
  height: 65vh;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.scroll-skills::-webkit-scrollbar {
  display: none;
}

.language-content {
  padding: 0 var(--gap-sm);
}

.scroll-language {
  position: absolute;
  top: 0;
  left: 0;
  height: 80%;
  width: 100%;
  padding-top: calc(var(--gap-xl) + var(--gap-md));
}

.language-content {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.language-content::-webkit-scrollbar {
  display: none;
}

.education-content {
  width: 100%;
  height: 80%;
  padding-top: calc(var(--gap-xl) + var(--gap-md));
  position: absolute;
  top: 0px;
  left: 0px;
}

.skills-column img {
  width: 30px;
}

.language-column img {
  width: 50px;
}

.skills-flex-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: baseline;
}

.skills-flex-wrapper > div {
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 var(--gap-md) var(--gap-lg);
}

.skills-flex-wrapper > div:hover {
  transform: scale(1.3);
}

.skills-flex-wrapper > div span {
  display: block;
  text-align: center;
  margin-left: 5px;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.language-column__language-header,
.education-column__education-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: var(--gap-sm);
  transition: color 0.3s ease, background-color 0.3s ease;
}

.language-column__language-header {
  justify-content: flex-start;
  margin-top: 1.2rem;
}

.education-column__education-header {
  justify-content: space-between;
}

.language-column__language-header:hover,
.education-column__education-header:hover {
  background-color: var(--color-red);
  color: white;
}

.scroll-timeline {
  height: 100%;
  width: 100%;
  position: absolute;
  top: calc(var(--gap-xl) + var(--gap-md));
  left: 0;
}

.timeline {
  width: 100%;
  height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.timeline::-webkit-scrollbar {
  display: none;
}

.timeline:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 175px;
  width: 0;
  height: 100%;
  border: 2px solid var(--color-red);
}

.timeline-item {
  margin: 45px 16px 45px 200px;
  position: relative;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.1);
}

.timeline-item > span {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -30px;
}

.timeline-item > span:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-red);
  border: 2px solid var(--color-red);
  position: absolute;
}

.timeline-item > span:before {
  top: -10px;
}

.timeline-date {
  position: absolute;
  left: -180px;
  top: 25px;
  display: block;
  width: 135px;
  text-align: right;
}

.timeline-date span {
  padding: 0.5rem 0.7rem;
  background-color: var(--color-red);
  color: white;
  font-size: 28px;
  line-height: 1.3rem;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
}

.timeline-content h2 {
  margin-bottom: 0.8rem;
}

.timeline-content a {
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 1.3rem;
  color: var(--color-black);
  text-decoration: none;
}

.timeline-content img {
  margin-bottom: 2px;
  margin-left: 0.5rem;
}

.timeline-content p {
  margin-left: 0.5rem;
  padding: 0;
}

@media screen and (max-width: 400px) {
  .small-navigation {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .contacts-additional-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .small-navigation {
    justify-content: center;
  }

  .link-margin {
    margin-right: var(--gap-sm);
  }

  .moving_icons {
    width: 100%;
    text-align: center;
  }

  .icons-margin img:last-child {
    margin: var(--gap-md) 0 0 0;
  }

  .language-column__language-header h3 {
    flex-basis: 100%;
  }

  .timeline:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 32px;
    width: 0;
    height: 100%;
    border: 2px solid var(--color-red);
  }

  .timeline-item {
    margin: 95px 16px 45px 64px;
    position: relative;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.1);
  }

  .timeline-item > span {
    content: "";
    display: block;
    width: 0;
    position: absolute;
    left: -37px;
  }

  .timeline-item > span:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--color-red);
    border: 2px solid var(--color-red);
    position: absolute;
  }

  .timeline-item > span:before {
    top: -82px;
  }

  .timeline-date {
    position: absolute;
    left: 0px;
    top: -45px;
    display: block;
    width: 135px;
    text-align: left;
  }

  .timeline-date span {
    padding: 0.5rem 0.7rem;
    background-color: var(--color-red);
    color: white;
    font-size: 28px;
    line-height: 1.3rem;
    font-weight: 600;
    font-family: Montserrat, sans-serif;
  }
}

@media screen and (max-width: 767px) {
  header {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 48px;
  }

  header {
    border-top: 1px solid var(--color-black);
    border-right: 1px solid var(--color-black);
    border-bottom: 1px solid var(--color-black);
  }

  nav {
    width: 72px;
    height: 48px;
    padding: 14px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: var(--color-black);
  }

  footer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 48px;
    border: 1px solid var(--color-black);
  }

  .contact-icons {
    width: 100%;
    height: 100%;
    padding: var(--gap-xs);
  }

  .contact-icons ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .contact-icons li {
    display: inline-block;
    margin-right: var(--gap-sm);
    display: flex;
    align-items: center;
  }

  .contact-icons li:last-child {
    margin-right: 0;
  }

  .contact-icons a {
    display: flex;
  }

  .right {
    width: 100%;
    height: 100%;
  }

  #contacts .left,
  #about-me .left {
    padding: 0 var(--gap-md);
  }

  /*========================================================================*/
  /*=========================== Projects ===================================*/
  /*========================================================================*/

  .projects-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .scrolling-wrapper {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .scrolling-wrapper .project-card {
    width: 100vw;
    height: 100%;
    padding: var(--gap-md);
    position: absolute;
    transition: transform 0.5s;
  }

  .project-card-content,
  .project-card-back-content {
    width: calc(100vw - 32px);
    height: calc(100% - 32px);
    border: 2px solid var(--color-black);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--gap-md);
    background-color: var(--color-mint);
  }

  .project-image,
  .project-description {
    align-self: flex-start;
  }

  .project-name,
  .project-technologies {
    margin-top: auto;
  }

  .project-date,
  .project-links {
    align-self: flex-end;
  }

  .project-name,
  .project-date,
  .project-links {
    width: 100%;
    border-top: 2px solid var(--color-black);
  }

  .project-technologies {
    width: 100%;
  }

  .project-image,
  .project-name,
  .project-date,
  .project-link-block {
    padding: var(--gap-md);
  }

  .project-description {
    padding: var(--gap-md) var(--gap-md) 0 var(--gap-md);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .project-description::-webkit-scrollbar {
    display: none;
  }

  .project-technologies {
    padding: 0 var(--gap-md) var(--gap-md) var(--gap-md);
  }

  .project-links img {
    width: 24px;
    margin-right: 10px;
  }

  .project-image img {
    width: 100%;
    max-height: 58vh;
    object-fit: cover;
  }

  .project-links {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .project-link-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1;
    text-align: center;
    color: var(--color-black);
  }

  .project-link-block:nth-child(2) {
    border-left: 2px solid var(--color-black);
  }

  .project-technologies span {
    display: inline-block;
    background-color: var(--color-green);
    padding: 6px 8px;
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    z-index: 2;
  }

  .arrow img {
    width: 30px;
    height: 30px;
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  #skills-page-grid {
    height: calc(100vh - 100px);
  }

  #skills-page-grid {
    grid-template-rows: 2.4fr 0.3fr 0.3fr;
  }

  #skills-page-grid:has(.skills-column:hover) {
    grid-template-rows: 2.4fr 0.3fr 0.3fr;
  }

  #skills-page-grid:has(.language-column:hover) {
    grid-template-rows: 0.3fr 2.4fr 0.3fr;
  }

  #skills-page-grid:has(.education-column:hover) {
    grid-template-rows: 0.3fr 0.3fr 2.4fr;
  }

  .scroll-language {
    padding-top: var(--gap-xxl);
  }

  .skills-content {
    top: var(--gap-xxl);
  }

  .scroll-skills {
    padding: var(--gap-md);
  }

  .language-content {
    padding: 0 var(--gap-lg);
  }

  .timeline {
    height: 80%;
  }
}

@media screen and (min-width: 768px) {
  body {
    height: 100%;
    width: 100vw;
    overflow: hidden;
  }

  body {
    --indent: minmax(0, 1fr);
    --content: minmax(var(--min-width), var(--max-width));
    --content-height: minmax(0, var(--max-height));

    display: grid;

    grid-template-columns: var(--indent) 100px var(--content) var(--indent);
    grid-template-rows: var(--indent) var(--content-height) 100px var(--indent);
  }

  main {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  footer {
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    border: 2px solid var(--color-black);
  }

  header {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
  }

  nav {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    background-color: var(--color-black);
    position: relative;
    padding: 20px;
    z-index: 1;
  }

  main {
    border-top: 2px solid var(--color-black);
    border-right: 2px solid var(--color-black);
  }

  header {
    border-top: 2px solid var(--color-black);
    border-right: 2px solid var(--color-black);
    border-bottom: 2px solid var(--color-black);
  }

  .wrapper {
    position: relative;
  }

  .contact-icons {
    flex-direction: column;
    padding-top: var(--gap-md);
  }

  section.page-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 1s ease;
  }

  section.page-main.active {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s ease, visibility 1s ease;
  }

  .home-padding {
    padding-left: 6vw;
  }

  .half-width {
    width: 80%;
    max-width: 80%;
  }

  .frame {
    width: 300px;
    height: 300px;
    margin: 30px auto;
  }

  /*======================================================================*/
  /*========================== Skills ====================================*/
  /*======================================================================*/

  .skills-column {
    z-index: 1;
  }

  .language-column {
    z-index: 2;
  }

  .education-column {
    z-index: 3;
  }

  .skills-content {
    position: absolute;
    left: 0;
    top: var(--gap-xxl);
    padding-bottom: var(--gap-xxl);
    padding-top: 0;
    height: 100%;
    width: 100%;
  }

  .scroll-skills {
    width: 100%;
    height: 70%;
    padding: var(--gap-md);
    position: relative;
    overflow-y: scroll;
  }

  .education-content .flex-wrapper {
    justify-content: space-between;
  }

  .education-content .left {
    padding-right: 5vw;
  }

  .education-column__education-header span {
    font-family: "Archivo Narrow", sans-serif;
    font-size: 1.2rem;
    font-weight: 200;
    color: var(--color-black);
  }

  .education-column__education-header span a {
    color: var(--color-black);
    text-decoration: none;
  }

  /*======================================================================*/
  /*=========================== Timeline =================================*/
  /*======================================================================*/

  .education-content {
    position: sticky;
  }

  .education-content {
    padding-top: 0;
  }

  .scroll-timeline {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 82%;
  }

  .timeline-item {
    margin: 45px 0px 45px 200px;
  }

  /*========================================================================*/
  /*=========================== Projects ===================================*/
  /*========================================================================*/

  .projects-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .scrolling-wrapper::-webkit-scrollbar {
    display: none;
  }

  .scrolling-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .scrolling-wrapper .project-card {
    flex: 0 0 auto;
    width: 500px;
    height: 514px;
    margin-left: 7vw;
  }

  .scrolling-wrapper div:nth-child(2) {
    margin-left: 0;
  }

  .project-card-content,
  .project-card-back-content {
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-black);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-mint);
  }

  .project-image,
  .project-description {
    align-self: flex-start;
  }

  .project-name,
  .project-technologies {
    margin-top: auto;
  }

  .project-date,
  .project-links {
    align-self: flex-end;
  }

  .project-name,
  .project-date,
  .project-links {
    width: 100%;
    border-top: 2px solid var(--color-black);
  }

  .project-technologies {
    width: 100%;
  }

  .project-image,
  .project-name,
  .project-date,
  .project-link-block {
    padding: var(--gap-md);
  }

  .project-description {
    padding: var(--gap-md) var(--gap-md) 0 var(--gap-md);
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .project-description::-webkit-scrollbar {
    display: none;
  }

  .project-technologies {
    padding: 0 var(--gap-md) var(--gap-md) var(--gap-md);
  }

  .project-links img {
    width: 24px;
    margin-right: 10px;
  }

  .project-image img {
    width: 100%;
    object-fit: cover;
  }

  .projects-arrow {
    flex: 0 0 auto;
    position: relative;
    margin: 5vw;
  }

  .project-links {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .project-link-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1;
    text-align: center;
    color: var(--color-black);
  }

  .project-link-block:nth-child(2) {
    border-left: 2px solid var(--color-black);
  }

  .scroll-down {
    width: 60px;
    height: 60px;
    text-align: center;
  }

  .project-technologies span {
    display: inline-block;
    background-color: var(--color-green);
    padding: 6px 8px;
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-right: var(--gap-sm);
    margin-top: var(--gap-sm);
  }

  .arrow {
    display: none;
  }

  #send-email-form button {
    color: var(--color-black);
    background-color: inherit;
  }

  #send-email-form button:hover {
    color: #fff;
    background-color: var(--color-red);
    cursor: pointer;
  }
}

@media screen and (min-width: 800px) {
  .home-padding {
    padding-left: 10vw;
  }
}

@media screen and (min-height: 800px) {
  .scroll-about-me-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #skills-page-grid {
    height: 100%;
  }
}

@media screen and (min-width: 992px) {
  .about-me-flex-wrapper {
    flex-direction: row;
    height: 100%;
  }

  .scroll-about-me {
    overflow: hidden;
  }

  .scroll-about-me-text {
    position: absolute;
    top: 0;
    left: 40px;
    overflow: scroll;
    height: 100%;
    width: 45vw;
    padding: var(--gap-lg);
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scroll-about-me-text::-webkit-scrollbar {
    display: none;
  }

  .half-width {
    max-width: 50%;
    width: 45vw;
  }

  .frame {
    width: calc(100vw * 450 / 1300);
    height: calc(100vw * 450 / 1300);
  }

  /*======================================================================*/
  /*========================== Skills ====================================*/
  /*======================================================================*/

  #skills-page-grid {
    width: 100%;
    height: 100vh;
    transition: 500ms;
    display: grid;
    grid-template-columns: 2.4fr 0.3fr 0.3fr;
    grid-template-rows: 1fr;
    gap: 2px;
    background: var(--color-black);
  }

  :where(.skills-column, .language-column, .education-column) {
    background: inherit;
    transition: 300ms;
  }

  #skills-page-grid:has(.skills-column:hover) {
    grid-template-columns: 2.4fr 0.3fr 0.3fr;
    grid-template-rows: 1fr;
  }

  #skills-page-grid:has(.language-column:hover) {
    grid-template-columns: 0.3fr 2.4fr 0.3fr;
    grid-template-rows: 1fr;
  }

  #skills-page-grid:has(.education-column:hover) {
    grid-template-columns: 0.3fr 0.3fr 2.4fr;
    grid-template-rows: 1fr;
  }

  .scroll-language {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 92%;
    padding-top: 0;
  }

  .language-content {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding-left: 17vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .skills-wrapper {
    width: 70vw;
    height: 100%;
    position: absolute;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .skills-content {
    position: absolute;
    top: 0;
    left: var(--gap-xxl);
    padding: 7vh var(--gap-xxl) 7vh 0;
  }

  .scroll-skills {
    width: 100%;
    height: auto;
    padding: 0 0 0 3vw;
  }

  .education-content {
    width: 60vw;
    max-width: 55vw;
    height: 100%;
  }

  .contacts-flex-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .contacts-content div:nth-child(1) {
    width: 30%;
  }

  .contacts-content div:nth-child(2) {
    min-width: 20%;
  }

  .contacts-content div:nth-child(3) {
    width: 35%;
  }

  .contacts-content div.additional-info {
    width: 100%;
  }

  .contacts-additional-info {
    display: block;
    width: auto;
  }

  .timeline {
    height: 100%;
  }
}

@media screen and (min-width: 1250px) {
  .about-me-flex-wrapper {
    height: 100%;
  }

  .frame {
    width: 450px;
    height: 450px;
  }

  .skills-wrapper {
    width: 60vw;
  }

  .skills-content {
    top: 0;
    left: var(--gap-xxl);
    padding: 7vh var(--gap-xxl) 7vh 0;
  }

  .scroll-skills {
    height: 100%;
    width: 100%;
    padding: 0 0 0 5vw;
  }

  .language-content {
    height: 100%;
    width: 100%;
    max-width: 1050px;
    overflow: hidden;
    padding-left: 15vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .education-content {
    max-width: 60vw;
  }

  .scroll-about-me-text {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) and (max-height: 699px) {
  .scroll-skills {
    height: 100%;
  }

  .language-content {
    justify-content: flex-start;
  }

  .scrolling-wrapper .project-card {
    width: 400px;
    height: 410px;
  }

  .project-description {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .project-image,
  .project-name,
  .project-date,
  .project-link-block {
    padding: var(--gap-sm);
  }

  .project-name h3 {
    white-space: nowrap;
    overflow-x: hidden;
  }
}

.contact-icons {
  display: flex;
  align-items: center;
}

.contact-icons ul {
  list-style-type: none;
}

#about-me {
  background-color: var(--color-green);
}

#skills {
  background-color: var(--color-green);
}

#projects {
  background-color: var(--color-mint);
}

#contacts {
  background-color: var(--color-lilac);
}

.menu-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.perspective {
  perspective: 1000px;
}
