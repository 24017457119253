:root {
  background-color: var(--color-red);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .hide-in-mobile {
    display: none;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
}

section.page-main {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: calc(100% - 96px);
  transition: opacity 1s, visibility 1s;
  position: absolute;
  top: 48px;
  left: 0;
  overflow: hidden;
}

section.page-main.active {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s, visibility 1s;
}

.page-center {
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  display: flex;
}

.frame {
  width: 25vw;
  height: 25vw;
  clip-path: polygon(92.5% 50%, 96.98% 32.9%, 82.56% 22.68%, 75% 6.7%, 57.38% 8.15%, 41.32% .76%, 28.75% 13.19%, 11.7% 17.86%, 10.06% 35.46%, 0% 50%, 10.06% 64.54%, 11.7% 82.14%, 28.75% 86.81%, 41.32% 99.24%, 57.38% 91.85%, 75% 93.3%, 82.56% 77.32%, 96.98% 67.1%);
  background: #000;
  margin: 0 auto;
  position: relative;
}

.frame img {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  object-fit: cover;
  clip-path: inherit;
  position: absolute;
  inset: 2px;
}

@media screen and (max-width: 1200px) {
  .hide-in-small-width {
    display: none;
  }
}

.flex-wrapper {
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.page-center-right {
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  display: flex;
}

.contacts-content p {
  margin: 0 var(--gap-sm);
}

#send-email-form {
  margin: var(--gap-md) 0;
  color: var(--color-black);
  flex-direction: column;
  justify-content: center;
  align-items: start;
  display: flex;
}

#send-email-form input, #send-email-form textarea {
  margin: var(--gap-sm) 0;
  padding: var(--gap-md);
  width: 100%;
  color: var(--color-black);
  background-color: inherit;
  text-transform: uppercase;
  background: #ffffff1a;
  border: none;
  font-size: 1.2rem;
}

#send-email-form textarea {
  height: 100px;
}

#send-email-form input:hover, #send-email-form textarea:hover {
  opacity: 1;
  color: #fff;
  border-left: 2px solid var(--color-red);
}

#send-email-form input:focus, #send-email-form textarea:focus {
  opacity: 1;
  color: #fff;
  border: 0;
  border-left: 2px solid var(--color-red);
  outline: none;
}

#send-email-form button {
  margin: var(--gap-lg) 0;
  padding: var(--gap-sm);
  width: 100%;
  color: #fff;
  background-color: var(--color-red);
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  font-size: 28px;
  font-weight: 600;
  display: block;
}

.scroll-contacts {
  height: 100%;
  width: 100%;
}

.contacts-wrapper {
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contacts-wrapper::-webkit-scrollbar {
  display: none;
}

.contacts-flex-wrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.contacts-additional-info {
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.scroll-contacts {
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.scroll-contacts::-webkit-scrollbar {
  display: none;
}

.contacts-wrapper {
  overflow-y: scroll;
}

.email-form {
  width: 100%;
}

.contacts-flex-wrapper {
  justify-content: flex-start;
}

.lets-connect {
  padding: var(--gap-lg);
}

.scroll-about-me {
  height: 100%;
  width: 100%;
  padding: 0 var(--var-md);
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.scroll-about-me::-webkit-scrollbar {
  display: none;
}

.about-me-flex-wrapper {
  width: 100%;
  height: auto;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.frame {
  width: 250px;
  height: 250px;
  margin: 25px auto;
}

.moving_icons img {
  width: 60px;
  animation: 1s infinite moving-icons-animation;
}

@keyframes moving-icons-animation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.icons-margin img {
  margin: var(--gap-md) var(--gap-md) 0 0;
}

.small-navigation {
  margin: var(--gap-md) 0;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.small-navigation a {
  color: var(--color-black);
  letter-spacing: .1em;
  font-size: 24px;
  font-weight: normal;
  text-decoration: none;
}

.link {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.link-margin {
  margin-right: var(--gap-lg);
}

.link:before, .link:after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
}

.link:after {
  content: attr(replace);
  height: 100%;
  transform-origin: 50% 0;
  transition: transform .3s cubic-bezier(.76, 0, .24, 1);
  top: 0;
  transform: translate3d(0%, 200%, 0);
}

.link:hover:before {
  transform-origin: 50% 0;
  transform: scaleX(1);
}

.link:hover:after {
  transform: translate3d(0, 0, 0);
}

.link span {
  transition: transform .3s cubic-bezier(.76, 0, .24, 1);
  display: inline-block;
}

.link:hover span {
  transform: translate3d(0, -200%, 0);
}

.skills-column {
  background-color: var(--color-yellow);
  z-index: 1;
}

.language-column {
  background-color: var(--color-lilac);
  z-index: 2;
}

.education-column {
  background-color: var(--color-green);
  z-index: 3;
}

#skills-page-grid {
  width: 100%;
  height: 100%;
  background: var(--color-black);
  grid-template-rows: 2.5fr .25fr .25fr;
  gap: 2px;
  transition: all .5s;
  display: grid;
}

:where(.skills-column, .language-column, .education-column) {
  background: inherit;
  transition: all .3s;
}

#skills-page-grid:has(.skills-column:hover) {
  grid-template-rows: 2.5fr .25fr .25fr;
}

#skills-page-grid:has(.language-column:hover) {
  grid-template-rows: .25fr 2.5fr .25fr;
}

#skills-page-grid:has(.education-column:hover) {
  grid-template-rows: .25fr .25fr 2.5fr;
}

.skills-wrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.skills-content {
  top: var(--gap-xl);
  padding: 0 var(--gap-sm);
  padding-top: var(--gap-md);
  position: absolute;
  left: 0;
}

.scroll-skills {
  height: 65vh;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

.scroll-skills::-webkit-scrollbar {
  display: none;
}

.language-content {
  padding: 0 var(--gap-sm);
}

.scroll-language {
  height: 80%;
  width: 100%;
  padding-top: calc(var(--gap-xl)  + var(--gap-md));
  position: absolute;
  top: 0;
  left: 0;
}

.language-content {
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.language-content::-webkit-scrollbar {
  display: none;
}

.education-content {
  width: 100%;
  height: 80%;
  padding-top: calc(var(--gap-xl)  + var(--gap-md));
  position: absolute;
  top: 0;
  left: 0;
}

.skills-column img {
  width: 30px;
}

.language-column img {
  width: 50px;
}

.skills-flex-wrapper {
  width: 100%;
  height: 100%;
  flex-flow: wrap;
  place-content: baseline flex-start;
  align-items: center;
  display: flex;
}

.skills-flex-wrapper > div {
  margin: 0 0 var(--gap-md) var(--gap-lg);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: transform .3s;
  display: flex;
}

.skills-flex-wrapper > div:hover {
  transform: scale(1.3);
}

.skills-flex-wrapper > div span {
  text-align: center;
  margin-left: 5px;
  font-size: 1.2rem;
  line-height: 1.2rem;
  display: block;
}

.language-column__language-header, .education-column__education-header {
  padding: var(--gap-sm);
  flex-flow: wrap;
  align-items: center;
  transition: color .3s, background-color .3s;
  display: flex;
}

.language-column__language-header {
  justify-content: flex-start;
  margin-top: 1.2rem;
}

.education-column__education-header {
  justify-content: space-between;
}

.language-column__language-header:hover, .education-column__education-header:hover {
  background-color: var(--color-red);
  color: #fff;
}

.scroll-timeline {
  height: 100%;
  width: 100%;
  top: calc(var(--gap-xl)  + var(--gap-md));
  position: absolute;
  left: 0;
}

.timeline {
  width: 100%;
  height: 95%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.timeline::-webkit-scrollbar {
  display: none;
}

.timeline:before {
  content: "";
  width: 0;
  height: 100%;
  border: 2px solid var(--color-red);
  display: block;
  position: absolute;
  top: 0;
  left: 175px;
}

.timeline-item {
  background: #ffffff1a;
  margin: 45px 16px 45px 200px;
  padding: 10px 20px;
  position: relative;
}

.timeline-item > span {
  content: "";
  width: 0;
  display: block;
  position: absolute;
  top: 40px;
  left: -30px;
}

.timeline-item > span:before {
  content: "";
  width: 10px;
  height: 10px;
  background: var(--color-red);
  border: 2px solid var(--color-red);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -10px;
}

.timeline-date {
  width: 135px;
  text-align: right;
  display: block;
  position: absolute;
  top: 25px;
  left: -180px;
}

.timeline-date span {
  background-color: var(--color-red);
  color: #fff;
  padding: .5rem .7rem;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.3rem;
}

.timeline-content h2 {
  margin-bottom: .8rem;
}

.timeline-content a {
  color: var(--color-black);
  margin-left: .5rem;
  font-size: 1.3rem;
  text-decoration: none;
  display: inline-block;
}

.timeline-content img {
  margin-bottom: 2px;
  margin-left: .5rem;
}

.timeline-content p {
  margin-left: .5rem;
  padding: 0;
}

@media screen and (max-width: 400px) {
  .small-navigation {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .contacts-additional-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .small-navigation {
    justify-content: center;
  }

  .link-margin {
    margin-right: var(--gap-sm);
  }

  .moving_icons {
    width: 100%;
    text-align: center;
  }

  .icons-margin img:last-child {
    margin: var(--gap-md) 0 0 0;
  }

  .language-column__language-header h3 {
    flex-basis: 100%;
  }

  .timeline:before {
    content: "";
    width: 0;
    height: 100%;
    border: 2px solid var(--color-red);
    display: block;
    position: absolute;
    top: 0;
    left: 32px;
  }

  .timeline-item {
    background: #ffffff1a;
    margin: 95px 16px 45px 64px;
    padding: 10px 20px;
    position: relative;
  }

  .timeline-item > span {
    content: "";
    width: 0;
    display: block;
    position: absolute;
    left: -37px;
  }

  .timeline-item > span:before {
    content: "";
    width: 10px;
    height: 10px;
    background: var(--color-red);
    border: 2px solid var(--color-red);
    border-radius: 50%;
    display: block;
    position: absolute;
    top: -82px;
  }

  .timeline-date {
    width: 135px;
    text-align: left;
    display: block;
    position: absolute;
    top: -45px;
    left: 0;
  }

  .timeline-date span {
    background-color: var(--color-red);
    color: #fff;
    padding: .5rem .7rem;
    font-family: Montserrat, sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  header {
    z-index: 1;
    width: 100%;
    height: 48px;
    border-top: 1px solid var(--color-black);
    border-right: 1px solid var(--color-black);
    border-bottom: 1px solid var(--color-black);
    position: fixed;
    bottom: 0;
    left: 0;
  }

  nav {
    width: 72px;
    height: 48px;
    z-index: 2;
    background-color: var(--color-black);
    padding: 14px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  footer {
    z-index: 1;
    width: 100%;
    height: 48px;
    border: 1px solid var(--color-black);
    position: fixed;
    top: 0;
    left: 0;
  }

  .contact-icons {
    width: 100%;
    height: 100%;
    padding: var(--gap-xs);
  }

  .contact-icons ul {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  .contact-icons li {
    margin-right: var(--gap-sm);
    align-items: center;
    display: flex;
  }

  .contact-icons li:last-child {
    margin-right: 0;
  }

  .contact-icons a {
    display: flex;
  }

  .right {
    width: 100%;
    height: 100%;
  }

  #contacts .left, #about-me .left {
    padding: 0 var(--gap-md);
  }

  .projects-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .scrolling-wrapper {
    width: 100%;
    height: 100%;
    flex-flow: row;
    justify-content: flex-start;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .scrolling-wrapper .project-card {
    width: 100vw;
    height: 100%;
    padding: var(--gap-md);
    transition: transform .5s;
    position: absolute;
  }

  .project-card-content, .project-card-back-content {
    width: calc(100vw - 32px);
    height: calc(100% - 32px);
    border: 2px solid var(--color-black);
    margin: var(--gap-md);
    background-color: var(--color-mint);
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .project-image, .project-description {
    align-self: flex-start;
  }

  .project-name, .project-technologies {
    margin-top: auto;
  }

  .project-date, .project-links {
    align-self: flex-end;
  }

  .project-name, .project-date, .project-links {
    width: 100%;
    border-top: 2px solid var(--color-black);
  }

  .project-technologies {
    width: 100%;
  }

  .project-image, .project-name, .project-date, .project-link-block {
    padding: var(--gap-md);
  }

  .project-description {
    padding: var(--gap-md) var(--gap-md) 0 var(--gap-md);
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
  }

  .project-description::-webkit-scrollbar {
    display: none;
  }

  .project-technologies {
    padding: 0 var(--gap-md) var(--gap-md) var(--gap-md);
  }

  .project-links img {
    width: 24px;
    margin-right: 10px;
  }

  .project-image img {
    width: 100%;
    max-height: 58vh;
    object-fit: cover;
  }

  .project-links {
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  .project-link-block {
    width: 100%;
    height: 100%;
    text-align: center;
    color: var(--color-black);
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .project-link-block:nth-child(2) {
    border-left: 2px solid var(--color-black);
  }

  .project-technologies span {
    background-color: var(--color-green);
    margin-top: .5rem;
    margin-right: .5rem;
    padding: 6px 8px;
    font-size: 1.2rem;
    line-height: 1.2rem;
    display: inline-block;
  }

  .arrow {
    width: 40px;
    height: 60px;
    cursor: pointer;
    z-index: 2;
    background-color: #ffffff80;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .arrow img {
    width: 30px;
    height: 30px;
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  #skills-page-grid {
    height: calc(100vh - 100px);
    grid-template-rows: 2.4fr .3fr .3fr;
  }

  #skills-page-grid:has(.skills-column:hover) {
    grid-template-rows: 2.4fr .3fr .3fr;
  }

  #skills-page-grid:has(.language-column:hover) {
    grid-template-rows: .3fr 2.4fr .3fr;
  }

  #skills-page-grid:has(.education-column:hover) {
    grid-template-rows: .3fr .3fr 2.4fr;
  }

  .scroll-language {
    padding-top: var(--gap-xxl);
  }

  .skills-content {
    top: var(--gap-xxl);
  }

  .scroll-skills {
    padding: var(--gap-md);
  }

  .language-content {
    padding: 0 var(--gap-lg);
  }

  .timeline {
    height: 80%;
  }
}

@media screen and (min-width: 768px) {
  body {
    height: 100%;
    width: 100vw;
    --indent: minmax(0, 1fr);
    --content: minmax(var(--min-width), var(--max-width));
    --content-height: minmax(0, var(--max-height));
    grid-template-columns: var(--indent) 100px var(--content) var(--indent);
    grid-template-rows: var(--indent) var(--content-height) 100px var(--indent);
    display: grid;
    overflow: hidden;
  }

  main {
    grid-area: 2 / 3 / 3 / 4;
  }

  footer {
    border: 2px solid var(--color-black);
    grid-area: 2 / 2 / 4 / 3;
  }

  header {
    grid-area: 3 / 3 / 4 / 4;
  }

  nav {
    background-color: var(--color-black);
    z-index: 1;
    grid-area: 3 / 2 / 4 / 3;
    padding: 20px;
    position: relative;
  }

  main {
    border-top: 2px solid var(--color-black);
    border-right: 2px solid var(--color-black);
  }

  header {
    border-top: 2px solid var(--color-black);
    border-right: 2px solid var(--color-black);
    border-bottom: 2px solid var(--color-black);
  }

  .wrapper {
    position: relative;
  }

  .contact-icons {
    padding-top: var(--gap-md);
    flex-direction: column;
  }

  section.page-main {
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s, visibility 1s;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  section.page-main.active {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s, visibility 1s;
  }

  .home-padding {
    padding-left: 6vw;
  }

  .half-width {
    width: 80%;
    max-width: 80%;
  }

  .frame {
    width: 300px;
    height: 300px;
    margin: 30px auto;
  }

  .skills-column {
    z-index: 1;
  }

  .language-column {
    z-index: 2;
  }

  .education-column {
    z-index: 3;
  }

  .skills-content {
    left: 0;
    top: var(--gap-xxl);
    padding-bottom: var(--gap-xxl);
    height: 100%;
    width: 100%;
    padding-top: 0;
    position: absolute;
  }

  .scroll-skills {
    width: 100%;
    height: 70%;
    padding: var(--gap-md);
    position: relative;
    overflow-y: scroll;
  }

  .education-content .flex-wrapper {
    justify-content: space-between;
  }

  .education-content .left {
    padding-right: 5vw;
  }

  .education-column__education-header span {
    color: var(--color-black);
    font-family: Archivo Narrow, sans-serif;
    font-size: 1.2rem;
    font-weight: 200;
  }

  .education-column__education-header span a {
    color: var(--color-black);
    text-decoration: none;
  }

  .education-content {
    padding-top: 0;
    position: sticky;
  }

  .scroll-timeline {
    height: 100%;
    width: 82%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .timeline-item {
    margin: 45px 0 45px 200px;
  }

  .projects-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .scrolling-wrapper::-webkit-scrollbar {
    display: none;
  }

  .scrolling-wrapper {
    width: 100%;
    height: 100%;
    flex-wrap: nowrap;
    align-items: center;
    display: flex;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .scrolling-wrapper .project-card {
    width: 500px;
    height: 514px;
    flex: none;
    margin-left: 7vw;
  }

  .scrolling-wrapper div:nth-child(2) {
    margin-left: 0;
  }

  .project-card-content, .project-card-back-content {
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-black);
    background-color: var(--color-mint);
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .project-image, .project-description {
    align-self: flex-start;
  }

  .project-name, .project-technologies {
    margin-top: auto;
  }

  .project-date, .project-links {
    align-self: flex-end;
  }

  .project-name, .project-date, .project-links {
    width: 100%;
    border-top: 2px solid var(--color-black);
  }

  .project-technologies {
    width: 100%;
  }

  .project-image, .project-name, .project-date, .project-link-block {
    padding: var(--gap-md);
  }

  .project-description {
    padding: var(--gap-md) var(--gap-md) 0 var(--gap-md);
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .project-description::-webkit-scrollbar {
    display: none;
  }

  .project-technologies {
    padding: 0 var(--gap-md) var(--gap-md) var(--gap-md);
  }

  .project-links img {
    width: 24px;
    margin-right: 10px;
  }

  .project-image img {
    width: 100%;
    object-fit: cover;
  }

  .projects-arrow {
    flex: none;
    margin: 5vw;
    position: relative;
  }

  .project-links {
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  .project-link-block {
    width: 100%;
    height: 100%;
    text-align: center;
    color: var(--color-black);
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .project-link-block:nth-child(2) {
    border-left: 2px solid var(--color-black);
  }

  .scroll-down {
    width: 60px;
    height: 60px;
    text-align: center;
  }

  .project-technologies span {
    background-color: var(--color-green);
    margin-right: var(--gap-sm);
    margin-top: var(--gap-sm);
    padding: 6px 8px;
    font-size: 1.2rem;
    line-height: 1.2rem;
    display: inline-block;
  }

  .arrow {
    display: none;
  }

  #send-email-form button {
    color: var(--color-black);
    background-color: inherit;
  }

  #send-email-form button:hover {
    color: #fff;
    background-color: var(--color-red);
    cursor: pointer;
  }
}

@media screen and (min-width: 800px) {
  .home-padding {
    padding-left: 10vw;
  }
}

@media screen and (min-height: 800px) {
  .scroll-about-me-text {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  #skills-page-grid {
    height: 100%;
  }
}

@media screen and (min-width: 992px) {
  .about-me-flex-wrapper {
    height: 100%;
    flex-direction: row;
  }

  .scroll-about-me {
    overflow: hidden;
  }

  .scroll-about-me-text {
    height: 100%;
    width: 45vw;
    padding: var(--gap-lg);
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: absolute;
    top: 0;
    left: 40px;
    overflow: scroll;
  }

  .scroll-about-me-text::-webkit-scrollbar {
    display: none;
  }

  .half-width {
    max-width: 50%;
    width: 45vw;
  }

  .frame {
    width: 34.6154vw;
    height: 34.6154vw;
  }

  #skills-page-grid {
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    grid-template-rows: 1fr;
    grid-template-columns: 2.4fr .3fr .3fr;
    gap: 2px;
    transition: all .5s;
    display: grid;
  }

  :where(.skills-column, .language-column, .education-column) {
    background: inherit;
    transition: all .3s;
  }

  #skills-page-grid:has(.skills-column:hover) {
    grid-template-rows: 1fr;
    grid-template-columns: 2.4fr .3fr .3fr;
  }

  #skills-page-grid:has(.language-column:hover) {
    grid-template-rows: 1fr;
    grid-template-columns: .3fr 2.4fr .3fr;
  }

  #skills-page-grid:has(.education-column:hover) {
    grid-template-rows: 1fr;
    grid-template-columns: .3fr .3fr 2.4fr;
  }

  .scroll-language {
    height: 100%;
    width: 92%;
    padding-top: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .language-content {
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding-left: 17vw;
    display: flex;
    overflow-y: scroll;
  }

  .skills-wrapper {
    width: 70vw;
    height: 100%;
    flex-direction: row;
    align-items: center;
    display: flex;
    position: absolute;
    overflow: hidden;
  }

  .skills-content {
    top: 0;
    left: var(--gap-xxl);
    padding: 7vh var(--gap-xxl) 7vh 0;
    position: absolute;
  }

  .scroll-skills {
    width: 100%;
    height: auto;
    padding: 0 0 0 3vw;
  }

  .education-content {
    width: 60vw;
    max-width: 55vw;
    height: 100%;
  }

  .contacts-flex-wrapper {
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    display: flex;
  }

  .contacts-content div:nth-child(1) {
    width: 30%;
  }

  .contacts-content div:nth-child(2) {
    min-width: 20%;
  }

  .contacts-content div:nth-child(3) {
    width: 35%;
  }

  .contacts-content div.additional-info {
    width: 100%;
  }

  .contacts-additional-info {
    width: auto;
    display: block;
  }

  .timeline {
    height: 100%;
  }
}

@media screen and (min-width: 1250px) {
  .about-me-flex-wrapper {
    height: 100%;
  }

  .frame {
    width: 450px;
    height: 450px;
  }

  .skills-wrapper {
    width: 60vw;
  }

  .skills-content {
    top: 0;
    left: var(--gap-xxl);
    padding: 7vh var(--gap-xxl) 7vh 0;
  }

  .scroll-skills {
    height: 100%;
    width: 100%;
    padding: 0 0 0 5vw;
  }

  .language-content {
    height: 100%;
    width: 100%;
    max-width: 1050px;
    flex-direction: column;
    justify-content: center;
    padding-left: 15vw;
    display: flex;
    overflow: hidden;
  }

  .education-content {
    max-width: 60vw;
  }

  .scroll-about-me-text {
    flex-direction: column;
    justify-content: center;
    display: flex;
    overflow: auto;
  }
}

@media screen and (min-width: 768px) and (max-height: 699px) {
  .scroll-skills {
    height: 100%;
  }

  .language-content {
    justify-content: flex-start;
  }

  .scrolling-wrapper .project-card {
    width: 400px;
    height: 410px;
  }

  .project-description {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .project-image, .project-name, .project-date, .project-link-block {
    padding: var(--gap-sm);
  }

  .project-name h3 {
    white-space: nowrap;
    overflow-x: hidden;
  }
}

.contact-icons {
  align-items: center;
  display: flex;
}

.contact-icons ul {
  list-style-type: none;
}

#about-me, #skills {
  background-color: var(--color-green);
}

#projects {
  background-color: var(--color-mint);
}

#contacts {
  background-color: var(--color-lilac);
}

.menu-list {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.perspective {
  perspective: 1000px;
}

/*# sourceMappingURL=index.3813bc34.css.map */
